export const tenant_code = "t.haier.wh";
// 场景类型
export const typeList = [
	"园区",
	"大楼",
	"餐厅",
	"接待大厅",
	"参观通道",
	"车间",
	"办公室",
	"电梯间",
	"会议室",
];
// 品牌列表
export const brandList = [
	"海尔",
	"TCL",
	"小米",
	"长虹",
	"海信",
	"乐视",
	"康佳",
	"三星",
	"LG",
	"其他",
];
// 元素埋点
export const eventInfo = {
	video_center: { page_id: "39_001" },
	work_manage: { page_id: "39_002" },
	work_detail: { page_id: "39_003" },
	publish_manage: { page_id: "39_004" },
	welcome_full: { page_id: "39_005" },
	workbenches: { page_id: "39_007" },
	add_works: {
		page_id: "39_002",
		element_id: "E39_002_003",
		event_name: "hiculture_content_upload",
	},
	add_work: {
		page_id: "39_002",
		element_id: "E39_002_004",
		event_name: "hiculture_content_upload",
	},
	video_publish_work: {
		page_id: "39_001",
		element_id: "E39_001_003",
		event_name: "hiculture_content_publish",
	},
	fast_publish_work: {
		page_id: "39_002",
		element_id: "E39_002_005",
		event_name: "hiculture_content_publish",
	},
	publish_work: {
		page_id: "39_004",
		element_id: "E39_004_003",
		event_name: "hiculture_content_publish",
	},
	publish_live: {
		page_id: "39_004",
		element_id: "E39_004_004",
		event_name: "hiculture_content_publish",
	},
	reload_publish: {
		page_id: "39_004",
		element_id: "E39_004_005",
		event_name: "hiculture_content_publish",
	},
};

// 转码列表
export const transCode = {
	orgin: "原视频",
	"MP4-SD": "MP4-标清",
	"MP4-HD": "MP4-高清",
	"M3U8-SD": "M3U8-标清",
	"M3U8-HD": "M3U8-高清",
};

// 系统列表
export const systemList = ["Windows", "Android"];

export const typeFilters = typeList.map(item => {
	return {
		text: item,
		value: item,
	};
});

// 添加权限更改说明
// 1.rightsContact，value格式a_b,注意只存在一个_
// 2.menusBus或menusEnter的code 用于判断是否显示父级菜单
// 3.rightsEnd 用于新建角色detail是否选中禁用

export const rightsEnd = ["edit", "del", "publish", "end", "lock", "sync"]; //编辑，删除，发布，结束

// 菜单code对照
export const rightsContact = {
	1000: "base_detail", // 企业信息
	1001: "base_edit",
	2000: "user_detail", // 用户
	2001: "user_edit",
	2002: "user_del",
	3000: "role_detail", // 角色
	3001: "role_edit",
	3002: "role_del",
	4000: "organ_detail", // 组织
	4001: "organ_edit",
	4002: "organ_del",
	5000: "tag_detail", // 标签
	5001: "tag_edit",
	5002: "tag_del",
	6000: "log_detail", // 日志
	100000: "scene_detail", // 场景
	100001: "scene_edit",
	100002: "scene_del",
	200000: "device_detail", // 设备
	200001: "device_edit",
	200002: "device_del",
	300000: "publish_detail", // 发布
	300001: "publish_edit",
	300002: "publish_del",
	300003: "publish_publish",
	300004: "publish_end",
	300005: "publish_lock",
	300006: "publish_sync", //废弃
	400000: "work_detail", // 作品
	400001: "work_edit",
	400002: "work_del",
	400003: "work_publish",
	400005: "work_lock",
	400006: "work_sync", //作品管理-同步海尔人
	500000: "player_detail", // 播放器
	500001: "player_edit",
	500002: "player_del",
	600000: "welcome_detail", //数据驾驶舱
	700000: "version_detail", //版本更新
	700001: "version_edit",
	700002: "version_del",
	800000: "videocenter_detail",
	800001: "videocenter_publish", //发布
	800005: "videocenter_lock", //锁定
	900000: "statistics_detail", //播放统计
	900001: "statistics_export", //播放导出

	999999999: "workbenches_detail", // 工作台权限 无需配置 前端赋予所有用户
};
// 权限初始化
export let rights = {};
for (let i in rightsContact) {
	rights[rightsContact[i]] = false;
}
rights["workbenches_detail"] = true;

// 业务菜单，控制菜单显示
export const menusBus = [
	{
		mid: "1",
		mname: "工作台", //菜单名称
		index: "1",
		icon: "apartment", // 图标
		nav: "workbenches",
		code: ["workbenches_detail"], //包含权限
	},
	{
		mid: "2",
		mname: "物联设备", //菜单名称
		icon: "laptop", // 图标
		code: [
			"scene_detail",
			"scene_edit",
			"scene_del",
			"device_detail",
			"device_edit",
			"device_del",
			"player_detail",
			"player_edit",
			"player_del",
		], //包含权限
		children: [
			{
				index: "2.1",
				uname: "场景管理",
				nav: "scene-manage",
				code: "scene_detail", // 控制子菜单显示的权限，一般就是_detail
			},
			{
				index: "2.2",
				uname: "设备管理",
				nav: "equipment-manage",
				code: "device_detail",
			},
			{
				index: "2.3",
				uname: "播放器管理",
				nav: "player-manage",
				code: "player_detail",
			},
		],
	},
	{
		mid: "3",
		mname: "内容中心",
		icon: "picture",
		code: [
			"work_detail",
			"work_edit",
			"work_del",
			"work_lock",
			"videocenter_detail",
			"videocenter_lock",
			"work_sync",
		],
		children: [
			{
				index: "3.2",
				uname: "公共资源库",
				nav: "video-center",
				code: "videocenter_detail",
			},
			{
				index: "3.1",
				uname: "作品管理",
				nav: "works-manage",
				code: "work_detail",
			},
			// {
			//     index: "3.2",
			//     uname: "作品审批",
			//     nav: "AuditManage",
			//     code: "work_detail",
			// },
		],
	},
	{
		mid: "4",
		mname: "发布中心",
		icon: "video-camera",
		code: [
			"publish_detail",
			"publish_edit",
			"publish_del",
			"publish_publish",
			"publish_end",
			"publish_lock",
			// "publish_sync",
		],
		children: [
			{
				index: "4.1",
				uname: "发布管理",
				nav: "publish-manage",
				code: "publish_detail",
			},
		],
	},
	{
		mid: "5",
		mname: "数据中心",
		icon: "cluster",
		code: ["welcome_detail", "statistics_detail"],
		children: [
			{
				index: "5.1",
				uname: "数据驾驶舱",
				nav: "welcome-full",
				code: "welcome_detail",
			},
			{
				index: "5.2",
				uname: "播放统计",
				nav: "data-statistics",
				code: "statistics_detail",
			},
		],
	},
];
// 企业权限菜单
export const menusEnter = [
	{
		mid: "10",
		mname: "基本信息",
		icon: "file-text",
		code: ["base_detail", "base_edit"],
		children: [
			{
				index: "10.1",
				uname: "企业信息",
				nav: "enterprise-info",
				code: "base_detail",
			},
		],
	},
	{
		mid: "11",
		mname: "组织架构",
		icon: "deployment-unit",
		code: [
			"user_detail",
			"user_edit",
			"user_del",
			"role_detail",
			"role_edit",
			"role_del",
			"organ_detail",
			"organ_edit",
			"organ_del",
		],
		children: [
			{
				index: "11.1",
				uname: "用户管理",
				nav: "user-manage",
				code: "user_detail",
			},
			{
				index: "11.2",
				uname: "角色权限",
				nav: "role-manage",
				code: "role_detail",
			},
			{
				index: "11.3",
				uname: "资源管理",
				nav: "organ-manage",
				code: "organ_detail",
			},
		],
	},
	{
		mid: "12",
		mname: "系统配置",
		icon: "laptop",
		code: ["tag_detail", "tag_edit", "tag_del"],
		children: [
			{
				index: "12.1",
				uname: "标签配置",
				nav: "label-manage",
				code: "tag_detail",
			},
		],
	},
	{
		mid: "13",
		mname: "日志管理",
		icon: "profile",
		code: ["log_detail"],
		children: [
			{
				index: "13.1",
				uname: "操作日志",
				nav: "log-manage",
				code: "log_detail",
			},
		],
	},
	{
		mid: "14",
		mname: "终端配置",
		icon: "apartment",
		code: ["version_detail"],
		children: [
			{
				index: "14.1",
				uname: "版本中心",
				nav: "veison-update",
				code: "version_detail",
			},
		],
	},
];
