import Vue from 'vue';
import Router from 'vue-router';
// import Main from '@/view/Main';
// import login from '@/view/login';
// import NoAccess from '@/view/NoAccess';
// import WelcomeFull from '@/view/base/welcome-full';
// import TestDetail from '@/view/TestDetail';
// import Welcome from '@/view/base/welcome';
const Main = () => import("@/view/Main");
const login = () => import("@/view/login");
const NoAccess = () => import("@/view/NoAccess");
const WelcomeFull = () => import("@/view/base/welcome-full");
const TestDetail = () => import("@/view/TestDetail");
const Welcome = () => import("@/view/base/welcome");
const LiveVideo = () => import("@/view/base/live-video");

// 合并所有router
let routers = [];
const requireComponent = require.context('./modules', false, /\.js$/); // modules下任意js文件
requireComponent.keys().forEach(fileName => {
    const item = requireComponent(fileName);
    routers = routers.concat(item.default); // modules中文件的写法是export default
});

Vue.use(Router);
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'main',
            component: Main,
            redirect: '/workbenches',
            linkActiveClass: 'active',
            children: routers
        },
        {
            path: '*',
            component: Error
        },
        {
            path: '/login',
            name: 'login',
            component: login
        },
        {
            path: '/no-access',
            name: 'NoAccess',
            component: NoAccess
        },
        {
            path: '/test-detail',
            name: 'TestDetail',
            component: TestDetail
        },
        {
            path: '/welcome-full',
            name: 'WelcomeFull',
            component: WelcomeFull
        },
        {
            path: '/welcome',
            name: 'Welcome',
            component: Welcome
        },
        {
            path: '/live-video',
            name: 'LiveVideo',
            component: LiveVideo
        },
        
    ]
});
export default router;
