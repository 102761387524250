const enterpriseInfo = () => import('@/view/enterprise/enterprise-info');
const UserManage = () => import('@/view/enterprise/user-manage');
const RoleManage = () => import('@/view/enterprise/role-manage');
const OrganManage = () => import('@/view/enterprise/organ-manage');
const LabelManage = () => import('@/view/enterprise/label-manage');
const LogManage = () => import('@/view/enterprise/log-manage');
const UserInfo = () => import('@/view/enterprise/user-info');
const VeisonUpdate = () => import('@/view/enterprise/version-update');
export default [
    {
        path: '/enterprise-info',
        name: 'enterpriseInfo',
        component: enterpriseInfo
    },
    {
        path: '/user-manage',
        name: 'UserManage',
        component: UserManage
    },
    {
        path: '/role-manage',
        name: 'RoleManage',
        component: RoleManage
    },
    {
        path: '/organ-manage',
        name: 'OrganManage',
        component: OrganManage
    },
    {
        path: '/label-manage',
        name: 'LabelManage',
        component: LabelManage
    },
    {
        path: '/user-info',
        name: 'UserInfo',
        component: UserInfo
    },
    {
        path: '/log-manage',
        name: 'LogManage',
        component: LogManage
    },
    {
        path: '/veison-update',
        name: 'VeisonUpdate',
        component: VeisonUpdate
    }
];