import request from "../../request/request";
import apis from "../../request/api";
import { rightsContact, rights, rightsEnd } from "../../assets/js/enum";
const typeMap = {
	1: "Province",
	2: "City",
	3: "Area",
};
const state = {
	treeData: [], //组织树
	organs: {}, //组织列表，用来查询组织id对应名称
	organsRight: {}, //组织列表，用来查询组织id对应是否可操作
	areaList: [], // 省市区一维列表
	rights: rights, // 当前用户权限表
	sceneAll: [], // 所有的场景列表包含播放器
	rightsType: localStorage.getItem("rightsType")
		? JSON.parse(localStorage.getItem("rightsType"))
		: "", // 企业或业务enterprise或business，用于右上角入口显示
	HaiBianAppId: "",
	WsScreenUrl: "",
	ManagerInfo: localStorage.getItem("ManagerInfo")
		? JSON.parse(localStorage.getItem("ManagerInfo"))
		: "",
	Manual: "",
	ParkNum: "48",
	treeFathers: {},
	isupLoading: false,
	// contentTag: {
	// 	music: { Id: 0 },
	// 	music_header: { Id: 0 },
	// },
};
const getters = {
	// contentTag: state => state.contentTag,
	treeData: state => state.treeData,
	organs: state => state.organs,
	organsRight: state => state.organsRight,
	areaList: state => state.areaList,
	rights: state => state.rights,
	rightsType: state => state.rightsType,
	sceneAll: state => state.sceneAll,
	HaiBianAppId: state => state.HaiBianAppId,
	WsScreenUrl: state => state.WsScreenUrl,
	ManagerInfo: state => state.ManagerInfo,
	Manual: state => state.Manual,
	ParkNum: state => state.ParkNum,
	areasObj: state => {
		const areasObj = {};
		state.areaList.forEach(item => {
			const obj = {
				[item.Id]: item[typeMap[item.Type]],
			};
			Object.assign(areasObj, obj);
		});
		return areasObj;
	}, // 省市区对照对象
	treeFathers: state => state.treeFathers,
	isupLoading: state => state.isupLoading,
};
const mutations = {
	setUpLoad(state, data) {
		state.isupLoading = data;
	},
	updateOrgTree(state, data) {
		state.treeData = data;
	},
	updateOrgans(state, data) {
		state.organs = data;
	},
	updateOrgansRight(state, data) {
		state.organsRight = data;
	},
	updateRightsType(state, data) {
		localStorage.setItem("rightsType", JSON.stringify(data));
		state.rightsType = JSON.parse(localStorage.getItem("rightsType"));
	},
	updateRights(state, data) {
		data.forEach(item => {
			const key = rightsContact[item.Id];
			state.rights[key] = true;
			const arr = key ? key.split("_") : [];
			if (key && rightsEnd.includes(arr[arr.length - 1])) {
				// 编辑选中，查看也选中
				state.rights[key.split("_")[0] + "_detail"] = true;
			}
		});
	},
	setTreeFathers(state, data) {
		state.treeFathers = data;
	},
};
const actions = {
	async getFrontUrl({ state }) {
		await request.get(apis.getUrl).then(res => {
			state.HaiBianAppId = res.HaiBianAppID;
			state.WsScreenUrl = res.WsScreenURL;
			// state.ManagerInfo = res.ManagerInfo;
			state.Manual = res.Manual;
			state.ParkNum = res.ParkNum;
			// 管理员存本地永久使用
			localStorage.setItem(
				"ManagerInfo",
				JSON.stringify(res.ManagerInfo)
			);
			localStorage.setItem("AdminRoleId", res.AdminRoleId);
			state.ManagerInfo = JSON.parse(localStorage.getItem("ManagerInfo"));
			// state.contentTag = res.content_tag;
			if (window.location.protocol == "https:") {
				state.WsScreenUrl = res.WssScreenUrl;
			}
			console.log("协议ws", window.location.protocol, state.WsScreenUrl);
		});
	},
	getSceneAll({ state }) {
		request.get(apis.sceneAll).then(res => {
			state.sceneAll = res;
			// console.log(333333333,state.sceneAll)
		});
	},
	async getorgList({ state, mutations }) {
		// 一维数组id对应名称和操作权限
		await request.get(apis.getOrgIds, {}).then(
			res1 => {
				// console.log(33333, res1)
				let organs = {};
				let organsRight = {};
				if (!res1) return false;
				res1.forEach(item => {
					organs[item.Id] = item.OrgName;
					// console.log(444444,item)
					organsRight[item.Id] = item.EnableOperation == "Y";
				});
				state.organs = organs;
				state.organsRight = organsRight;
			},
			() => {}
		);
	},
	// getorgList({ state, mutations }) {
	// 	// 变更时机：进入项目，组织增删改查
	// 	request
	// 		.get(apis.TenantOrg, {
	// 			limit: 1,
	// 		})
	// 		.then(
	// 			// 获取全部组织
	// 			res => {
	// 				// 获取总条数
	// 				if (res.total_num) {
	// 					request
	// 						.get(apis.TenantOrg, {
	// 							limit: res.total_num,
	// 						})
	// 						.then(
	// 							res1 => {
	// 								let organs = {};
	// 								let organsRight ={};
	// 								res1.list.forEach(item => {
	// 									organs[item.Id] = item.OrgName;
	// 									// console.log(444444,item)
	// 									organsRight[item.Id] = item.EnableOperation=='Y';
	// 								});
	// 								state.organs = organs;
	// 								state.organsRight = organsRight;
	// 								// console.log(5555,organsRight)
	// 							},
	// 							() => {}
	// 						);
	// 				}
	// 			}
	// 		);
	// },
	async getAreaList({ state }) {
		// 获取省市区的一维列表
		await request
			.get(apis.regions, {
				limit: 10000,
			})
			.then(res => {
				state.areaList = res.list;
			});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
