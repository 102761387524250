const List = () => import("@/view/base/list");
const Workbenches = () => import("@/view/base/workbenches");
export default [
	{
		path: "/list",
		name: "list",
		component: List,
	},
	{
		path: "/workbenches",
		name: "Workbenches",
		component: Workbenches,
	},
];
