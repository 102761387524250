import request from "../../request/request.js";
import apis from "../../request/api.js";
import { accessKeyId, accessKeySecret } from "../../request/config.js";
import { uniqueId } from "../../assets/js/utils";
import OSS from "ali-oss";
/**
 * 判断移动端或pc端
 */
export function isPc() {
	return !/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
}

/**
 * 判断客户端
 */
export function getPlatform() {
	const u = window.navigator.userAgent.toLowerCase();
	if (/lark/.test(u)) return "iHaier";
	if (/seaside/.test(u)) return "haibian";
	return "web";
}

/**
 * 图片限制大小
 */
export function pictureLimit(img) {
	let res = img;
	if (img.indexOf("x-oss-process") == -1) {
		res = img + "?x-oss-process=image/resize,w_100,h_100/format,jpg";
	}
	return res;
}

/**
 * 获取飞书鉴权参数
 */
async function getSignParams(url) {
	let data = "";
	await request.get(apis.configParameters + "?url=" + url).then(res => {
		data = res;
	});
	return data;
}

/**
 * 飞书鉴权
 */
let retry = 5; // 重新鉴权次数
export const getFeishuSign = async () => {
	try {
		const url = encodeURIComponent(location.href);
		let res = await getSignParams(url);
		console.log("飞书鉴权参数", url, res, window.h5sdk);
		retry--;
		return new Promise((resolve, reject) => {
			window.h5sdk &&
				window.h5sdk.ready(() => {
					window.h5sdk.config({
						appId: res.appid,
						timestamp: res.timestamp,
						nonceStr: res.noncestr,
						signature: res.signature,
						jsApiList: [],
						//鉴权成功回调
						onSuccess: response => {
							retry = 5;
							sessionStorage.setItem("feishuSign", "success");
							console.log(`飞书鉴权成功config success: ${JSON.stringify(response)}`);
							resolve(response);
						},
						//鉴权失败回调
						onFail: err => {
							if (retry > 0) getSignParams(); // 如果失败 重试5次
							sessionStorage.setItem("feishuSign", "fail");
							reject(err);
							throw `飞书鉴权失败1config failed: ${JSON.stringify(err)}`;
						},
					});
				});
		});
	} catch (error) {
		console.log("飞书鉴权失败2", error);
	}
};
/**
 * 飞书跳转新页面（跳出主程序）
 */
export const dcmOpenSchema = url => {
	// // iHaier中打开
	const Platform = getPlatform();
	if (Platform == "iHaier") {
		console.log("从iHaier中打开新页面", url);
		window.h5sdk.ready(function () {
			window.tt.openSchema({
				schema: url,
				external: false,
				options: {
					width: 1200,
				},
				success(res) {
					console.log("打开新页面成功", res);
				},
				fail(res) {
					console.log("打开新页面失败", `${JSON.stringify(res)}`);
				},
			});
		});
	} else {
		window.open(url);
	}
};

// 选人组件2.0版本（支持选人+选部门）
export const dcmSelectComponent2 = ({
	alreadyContacts = [],
	multi = false,
	enableChooseDepartment = false,
	ignore = false,
	maxNum = 1000,
} = {}) => {
	return new Promise((resolve, reject) => {
		window.h5sdk.ready(() => {
			window.tt.chooseContact({
				multi: multi,
				ignore: ignore,
				maxNum: maxNum,
				externalContact: false, //否包含外部联系人，默认包含
				enableChooseDepartment: enableChooseDepartment, // 是否支持部门
				choosenIds: [...alreadyContacts], //指定已选取的openId数组，multi值为false时不生效
				limitTips: "选择联系人数量已超过上限",
				disableChosenIds: [], //指定openId列表为置灰、不可选择状态。 单选暂不支持
				success(res) {
					console.log(`ihaier 联系人列表`, res);
					resolve(res);
				},
				fail(res) {
					console.log(`获取联系人失败`, res);
				},
			});
		});
	});
};

/* 上传文件oss（分片）
params: 资源，媒体类型，成功回调，失败回调，进度，key
return: oss实例*/

export const uploadOss = (fileData, MediaType, func, err, progress, key) => {
	let client;

	client = new OSS({
		region: "oss-cn-beijing", // 根据那你的Bucket地点来填写
		accessKeyId: accessKeyId, // 自己账户的accessKeyId或临时秘钥
		accessKeySecret: accessKeySecret, // 自己账户的accessKeySecret或临时秘钥
		secure: true,
		bucket: MediaType == "image" ? "service-prod" : "history-prod", // bucket名字
		timeout: 660000,
	});
	// let type = fileData.type.split("/")[1];
	let nameArr = fileData.name.split(".");
	let type = nameArr[nameArr.length - 1];
	console.log("当前视频格式：", type);
	let cata = "";
	if (MediaType == "image") {
		cata = process.env.NODE_ENV == "production" ? "picture/zhanban/" : "picture/test/";
	} else {
		cata =
			process.env.NODE_ENV == "production" ? "service_video/uploads/" : "service_video/test/";
	}
	const name = `${cata}${uniqueId()}.${type}`;
	try {
		const result = client
			.multipartUpload(name, fileData, {
				progress: function (percentage) {
					progress(percentage, key);
				},
			})
			.then(res => {
				if (res.res.statusCode == 200) {
					const info = {
						url: res.res.requestUrls[0].split("?")[0],
						key,
					};
					func(info);
				} else {
					err();
				}
			})
			.catch(e => {
				// 中止上传或上传失败
				err(e);
			});
		// const result = await client.put(name, fileData);
		return { client, result };
	} catch (e) {
		err(e);
		console.log("上传抛出错误", e);
	}
};
// 分片上传文件oss(废弃)
export const uploadVideoOssPark = async fileData => {
	const client = new OSS({
		region: "oss-cn-beijing", // 根据那你的Bucket地点来填写
		accessKeyId: accessKeyId, // 自己账户的accessKeyId或临时秘钥
		accessKeySecret: accessKeySecret, // 自己账户的accessKeySecret或临时秘钥
		// stsToken: 'securityToken',//  从STS服务获取的安全令牌（SecurityToken）。
		secure: true,
		bucket: "history-prod", // bucket名字
		timeout: 120000,
	});
	let type = fileData.type.split("/")[1];
	const cata =
		process.env.NODE_ENV == "production" ? "service_video/uploads/" : "service_video/test/";
	const name = `${cata}${uniqueId()}.${type}`;
	const result = await client.initMultipartUpload(name);
	let fileSize = fileData.size;
	const partSize = 100 * 1024; //100kb 2326
	const done = [];
	let parkNum = Math.ceil(fileSize / partSize);
	// 将视频分为多份上传
	for (let i = 1; i <= parkNum; i++) {
		const start = partSize * (i - 1);
		const end = Math.min(start + partSize, fileSize);
		const data = fileData.slice(start, end);
		const part = await client.uploadPart(name, result.uploadId, i, data, 0, data.length);
		done.push({
			number: i,
			etag: part.res.headers.etag,
		});
	}
	// 上传完成
	const completeData = await client.completeMultipartUpload(name, result.uploadId, done);
	return completeData;
	// console.log(22222, completeData);
	// if (completeData.res.statusCode == 200) {
	// 	let url = completeData.res.requestUrls[0].split("?")[0];
	// 	return {
	// 		url,
	// 	};
	// }
};
// m3U8文件上传
export const putOss = async (fileData, func) => {
	const client = new OSS({
		region: "oss-cn-beijing", // 根据那你的Bucket地点来填写
		accessKeyId: accessKeyId, // 自己账户的accessKeyId或临时秘钥
		accessKeySecret: accessKeySecret, // 自己账户的accessKeySecret或临时秘钥
		// stsToken: 'securityToken',//  从STS服务获取的安全令牌（SecurityToken）。
		secure: true,
		bucket: "history-prod", // bucket名字
		timeout: 120000,
	});
	const name = `index.m3u8`;
	client.multipartUpload(name, fileData).then(res => {
		if (res.res.statusCode == 200) {
			const info = {
				url: res.res.requestUrls[0].split("?")[0],
			};
			func(info);
		}
	});
};
