<template>
    <a-config-provider :locale="zh_CN">
        <div id="app">
            <router-view></router-view>
        </div>
    </a-config-provider>
</template>

<script>
import apis from "./request/api";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
    name: "app",
    data() {
        return {
            zh_CN,
            isLoading: false,
        };
    },
    components: {},
    created() {
        // ★★★挂载全局api
        for (const key in apis) {
            this.$root[key] = apis[key];
        }
    },
    // methods: {
    //     createkitchengod() {
    //         // 加载引入文件
    //         const jssrc =
    //             process.env.NODE_ENV == "production"
    //                 ? "https://r.haier.net/assets/prod/dts-fe/kitchengod/0.2.38/index.js"
    //                 : "https://cdn.haier.net/assets/daily/dts-fe/kitchengod/0.1.0/index.js";
    //         var myJs = document.createElement("script");
    //         myJs.src = jssrc;
    //         myJs.crossorigin = "anonymous";
    //         var s = document.getElementsByTagName("script")[0];
    //         s.parentNode.insertBefore(myJs, s);
    //     },
    //     GaodeKey() {
    //         // 引入高德地图
    //         const jssrc =
    //             process.env.NODE_ENV == "production"
    //                 ? "https://webapi.amap.com/maps?v=1.4.8&key=7ce5f6f4035dd3918b20d0cda7a1ccd3&plugin=AMap.Autocomplete,AMap.PlaceSearch,Map3D"
    //                 : "https://webapi.amap.com/maps?v=1.4.8&key=f825a6194236230c051cd5c3272e1cf1&plugin=AMap.Autocomplete,AMap.PlaceSearch,Map3D";
    //         var myJs = document.createElement("script");
    //         myJs.src = jssrc;
    //         var s = document.getElementsByTagName("script")[0];
    //         s.parentNode.insertBefore(myJs, s);
    //     }
    // },
};
</script>

<style>
#app {
    width: 100%;
    height: 100%;
}
</style>
