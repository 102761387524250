// 数据驾驶舱相关
const state = {
	currentOrgId: { id: 0, name: "" },
	deviceStat: [],
	onlineStat: [],
	durationStat: [],
	contentStat: [],
	mapHash: "", // 用于判断当前园区列表是否变更
	isLoading: "N",
	isOpen: "N",
	sceneId: "",
	typeName: "",
	sceneListAll: [],
	isUpdateDevice: "Y", // 是否更新设备
};
const getters = {
	currentOrgId: state => state.currentOrgId,
	deviceStat: state => state.deviceStat,
	onlineStat: state => state.onlineStat,
	durationStat: state => state.durationStat,
	contentStat: state => state.contentStat,
	mapHash: state => state.mapHash,
	isLoading: state => state.isLoading,
	isOpen: state => state.isOpen,
	sceneId: state => state.sceneId,
	isUpdateDevice: state => state.isUpdateDevice,
	typeName: state => state.typeName,
	sceneListAll: state => state.sceneListAll,
};
const mutations = {
	setOrgId(state, data) {
		state.currentOrgId = data;
		state.isLoading = "Y";
	},
	setOpen(state, data) {
		state.isOpen = data;
	},
	setLoading(state, data) {
		state.isLoading = data;
	},
	setdeviceStat(state, data) {
		state.deviceStat = data;
	},
	setOnlineState(state, data) {
		state.onlineStat = data;
	},
	setDurationStat(state, data) {
		state.durationStat = data;
	},
	setContentStat(state, data) {
		state.contentStat = data;
	},
	setMapHash(state, data) {
		state.mapHash = data;
	},
	setSceneId(state, data) {
		state.sceneId = data;
	},
	setIsUpdateDevice(state, data) {
		state.isUpdateDevice = data;
	},
	setTypeName(state, data) {
		state.typeName = data;
	},
	setSceneAll(state, data) {
		state.sceneListAll = data;
	},
};
const actions = {};

export default {
	state,
	getters,
	actions,
	mutations,
};
