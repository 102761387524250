import axios from "axios";
import router from "../router/router";
// import { iHaierUrl, hostUrl } from "../request/config.js";
import store from "../store/index.js";
import request from "../request/request.js";
import apis from "../request/api.js";
// import { dcm } from "dcmjssdk/src/dcm";
// import { getPlatform } from "../assets/js/dcmFunc";

// 跨域是否携带凭证
// axios.defaults.withCredentials = true;

export const action = axios.create({
	// timeout: 60000, // 超时时间一分钟
});

action.interceptors.response.use(responseSuccess, responseFail);

// 返回正常处理
function responseSuccess(response) {
	// console.log('清除刷新')
	// sessionStorage.setItem("reloaded", "");
	sessionStorage.setItem("isBan", "N");

	let retcode = response.data.response_status;

	if (retcode == "HOS000000000") {
		if (window.location.href.indexOf("no-access") > -1) {
			// 当前是禁用页，跳转首页
			router.replace({ path: "/workbenches" }, () => {});
		}
		return response.data.data;
	} else if (retcode == "HOS010050008") {
		// 用户被禁用
		sessionStorage.setItem("isBan", "Y");
		if (window.location.href.indexOf("no-access") == -1) {
			router.replace({ path: "/no-access" }, () => {});
		}
	} else if (retcode == "HOS010050010") {
		// 飞书code过期重新登录
		if (window.location.protocol.indexOf("https") > -1) {
			request
				.get(apis.configUserCenter)
				.then(res => {
					const data = res.config;
					window.__USERCENTER__.configUserCenter({
						clientId: data["user-center-client-id"], //账号中心cliendtId
						ssoUrl: data["user-center-sso-url"], //账号中心统一登录页
						appId: data["user-center-app-id"], //开放平台创建应用获取（open.feishu.cn）
						tokenUrl: data["user-center-token-url"], //集成了账号中心提供的后端服务的地址
					});
					window.__USERCENTER__.reLogin().then(res1 => {
						console.log("重新登录-ihaier返回token", res1);
						const token = localStorage.getItem(
							"haier-user-center-access-token"
						);
						const user = JSON.parse(
							localStorage.getItem("haier-user-center-user-info")
						);
						iHaierLogin(token, user.userName);
					});
				})
				.catch(err => {
					console.log("ihaier登录失败", err);
				});
		}
	} else if (
		["HOS010010001", "HOS010010002", "HOS010010003"].includes(retcode)
	) {
		// console.log("ihaier登录", window.location.protocol);
		if (window.location.protocol.indexOf("https") > -1) {
			const ihaierToken = localStorage.getItem(
				"haier-user-center-access-token"
			);
			if (!ihaierToken || ihaierToken == "undefined") {
				request
					.get(apis.configUserCenter)
					.then(res => {
						const data = res.config;
						window.__USERCENTER__.configUserCenter({
							clientId: data["user-center-client-id"], //账号中心cliendtId
							ssoUrl: data["user-center-sso-url"], //账号中心统一登录页
							appId: data["user-center-app-id"], //开放平台创建应用获取（open.feishu.cn）
							tokenUrl: data["user-center-token-url"], //集成了账号中心提供的后端服务的地址
						});
						window.__USERCENTER__.login().then(res1 => {
							console.log("登录-ihaier返回token", res1);
							if(res1.success){
								iHaierLogin(res1.token, res1.userInfo.userName);
							}
						});
					})
					.catch(err => {
						console.log("ihaier登录失败", err);
					});
			} else {
				// 重新进入首页，ihaierToken存在换取自己的token
				const user = JSON.parse(
					localStorage.getItem("haier-user-center-user-info")
				);
				iHaierLogin(ihaierToken, user.userName);
			}
		} else {
			// http跳转login页面
			router.replace({ path: "/login" }, () => {});
		}
		return Promise.reject(response.data.response_desc);
	} else {
		return Promise.reject(errMap[retcode] || "请刷新重试！");
	}
}

function iHaierLogin(token, userName) {
	request
		.post(apis.login, {
			OpType: "ihaier",
			Password: token,
			UserName: userName,
		})
		.then(res2 => {
			// token存本地
			// console.log(5555,res2)
			if (res2.token && res2.user) {
				store.commit("setToken", res2.token);
				store.commit("setUser", res2.user);
				if (window.location.href.indexOf("no-access") == -1) {
					// 当前是首页，刷新页面
					location.reload();
				}
			}
		});
}

// 返回异常处理
function responseFail(error) {
	return Promise.reject(error);
}

const errMap = {
	HOS010060000: "场景系统异常！",
	HOS010060001: "参数异常！",
	HOS010060002: "场景名称已存在！",
	HOS010060003: "场景正在使用！", //场景正在使用

	// 01007 Device
	HOS010070000: "设备系统异常！",
	HOS010070001: "参数异常！",
	HOS010070002: "设备正在使用！",
	HOS010070003: "设备编码已存在！", //设备编码重复(新增时，存在未删除的设备编码)
	HOS010070005: "设备名称重复！",
	// 01008 Player
	HOS010080000: "播放器系统异常！", //播放器系统异常
	HOS010080001: "参数异常！", //接口入参异常
	HOS010080002: "播放器正在使用！", //播放器正在使用(已关联播放器)
	HOS010080003: "播放器编码已存在！", //播放器编码重复(新增时，存在未删除的设备编码)
	HOS010080004: "播放器不存在！", //播放器Id不存在
	// 用户
	HOS010050000: "用户系统错误！", //用户系统错误
	HOS010050001: "参数异常！", //参数异常
	HOS010050002: "密码错误！", //无效密码
	HOS010050003: "用户已存在！", //存在重复用户
	HOS010050004: "用户不存在！", //用户不存在
	HOS010050005: "手机号已存在！", //存在重复用户（手机号重复）
	HOS010050006: "用户名已存在！", //存在重复用户（用户名重复）
	HOS010050007: "验证码错误！", //验证码错误
	HOS010050008: "用户被禁用！", //用户被禁用
	// 标签
	HOS010090000: "标签系统异常！", //标签系统异常
	HOS010090001: "参数异常！", //接口入参异常
	HOS010090002: "标签正在使用！", //标签正在使用(已关联播放器)
	HOS010090003: "标签名称已存在！", //标签名称重复(新增时，存在未删除)
	HOS010090004: "标签不存在！", //标签不存在
	// 01010 Program'
	HOS010100000: "作品节目系统异常！", //作品节目系统异常
	HOS010100001: "参数异常！", //接口入参异常
	HOS010100002: "作品节目正在使用！", //作品节目正在使用(已关联播放器)
	HOS010100003: "作品节目已存在！", //作品节目编码重复(新增时，存在未删除的设备编码)
	HOS010100004: "作品节目不存在！", //作品节目不存在
	HOS010100006: "已存在同名文件，请重新命名！", //作品节目版本名称重复

	//01012 org
	HOS010120000: "组织系统错误！", //组织系统错误
	HOS010120001: "参数异常！", //参数异常
	HOS010120002: "密码错误！", //无效密码
	HOS010120003: "组织名称已存在！", //存在重复组织名称
	HOS010120004: "组织不存在！", //组织不存在
	HOS010120006: "组织类型不是园区", //组织类型不是园区

	// 01011 Publish
	HOS010110000: "发布系统异常！", //发布系统异常
	HOS010110001: "参数异常", //接口入参异常
	HOS010110002: "发布正在使用！", //发布正在使用
	// "HOS010110003": '发布编码已存在！',  //发布编码重复(新增时，存在未删除的编码)
	HOS010110004: "发布不存在！", //发布不存在
	HOS010110003: "发布名称重复", // 发布名称重复
	HOS010110005: "未选择播放器", //发布时，选择播放器为空
	HOS010050009: "用户使用中 ！", //用户使用中
	HOS010120005: "组织使用中 ！", //组织使用中
	// 01013 角色
	HOS010130000: "角色系统异常！", //角色系统异常
	HOS010130001: "接口入参异常！", //接口入参异常
	HOS010130002: "角色正在使用(已关联用户)！", //角色正在使用(已关联角色)
	HOS010130003: "角色名称已存在！", //角色名称重复(新增时，存在未删除)
	HOS010130004: "角色不存在！", //角色不存在
	// 01015 版本更新
	HOS010150003: "该版本号已存在",
	HOS010150007: "应用包版本号只允许递增",
	HOS010110007: "与已存在锁定时间重合",
	HOS010100007: "已存在“音为你点歌台”标签的作品节目",
	HOS010100008: "已存在“音为你点歌台片头”标签的作品节目",
	HOS010100009: "已存在“午间新闻片头”标签的作品节目",
	HOS010100010: "已存在“午间新闻片尾”标签的作品节目",
};
