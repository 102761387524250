import "babel-polyfill";
import Es6Promise from "es6-promise";
Es6Promise.polyfill();
import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/index";
import request from "./request/request";
import apis from "./request/api";
import highcharts from "highcharts";
import Vconsole from "vconsole";
// import 'default-passive-events'
import highcharts3d from "highcharts/highcharts-3d";
import "@/assets/font/font.css";
import {
	ConfigProvider,
	Button,
	Spin,
	Tabs,
	TimePicker,
	Progress,
	InputNumber,
	Popover,
	Empty,
	Dropdown,
	Anchor,
	Layout,
	DatePicker,
	Steps,
	Upload,
	Icon,
	Card,
	Tooltip,
	Checkbox,
	message,
	Badge,
	Menu,
	Avatar,
	Radio,
	Tag,
	Input,
	Cascader,
	Divider,
	Switch,
	Select,
	Table,
	Pagination,
	Popconfirm,
	Alert,
	Modal,
	Tree,
	FormModel,
	Col,
	Row,
	Breadcrumb,
	TreeSelect,
} from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { getPlatform } from "@/assets/js/dcmFunc";

/* 按需注册子组件*/
[
	ConfigProvider,
	Button,
	Spin,
	Layout,
	Tabs,
	TimePicker,
	Progress,
	InputNumber,
	Popover,
	Empty,
	Dropdown,
	Anchor,
	DatePicker,
	Icon,
	Steps,
	Upload,
	Tooltip,
	Card,
	Menu,
	Checkbox,
	message,
	Badge,
	Avatar,
	Radio,
	Tag,
	Input,
	Cascader,
	Divider,
	Switch,
	Select,
	Table,
	Pagination,
	Popconfirm,
	Alert,
	Modal,
	Tree,
	FormModel,
	Col,
	Row,
	Breadcrumb,
	TreeSelect,
].forEach(item => {
	Vue.use(item);
});
highcharts3d(highcharts);

// ihaier或路径带标识的显示vconsole
const isHaier = getPlatform() == "iHaier" && process.env.NODE_ENV != "production";
const isShowVconsole =  window.location.href.indexOf('vconsole')>-1
if (isHaier || isShowVconsole) {
	const vConsole = new Vconsole();
	Vue.use(vConsole);
}
//全局注册enumxa
Vue.prototype.$api = apis;
Vue.prototype.request = request;
Vue.prototype.httpGet = request.get;
Vue.prototype.httpPost = request.post;
Vue.prototype.httpPut = request.put;
Vue.prototype.httpDelete = request.delete;
Vue.prototype.httpUppic = request.upPic;
Vue.prototype.httpCapture = request.capture;
Vue.prototype.$message = message;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$SocketTask = null;
// Vue.config.productionTip = false

router.onError(error => {
	// 长时间不操作，发包导致路由失效，在此提示
	const pattern = /Loading chunk (\d)+ failed/g;
	const isChunkLoadFailed = error.message.match(pattern);
	const targetPath = router.history.pending.fullPath;
	if (isChunkLoadFailed) {
		router.replace(targetPath);
		this.$message.warning("系统更新，请刷新页面重试~");
		console.log("路由错误啦", error.message, targetPath);
	}
});

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");
