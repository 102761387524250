const sceneManage = () => import('@/view/scene/scene-manage');
const equipmentManage = () => import('@/view/scene/equipment-manage');
const playerManage = () => import('@/view/scene/player-manage');
export default [
    {
        path: '/scene-manage',
        name: 'sceneManage',
        component: sceneManage
    },
    {
        path: '/equipment-manage',
        name: 'equipmentManage',
        component: equipmentManage
    },
    {
        path: '/player-manage',
        name: 'playerManage',
        component: playerManage
    }
];
