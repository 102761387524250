/**
 * 判空 Object Array String
 */
export function isEmpty(value) {
	if (value instanceof Object) {
		return Object.keys(value).length === 0;
	}
	if (value instanceof Array) {
		return value.length === 0;
	}
	return value === "" || value === null || value === undefined;
}

/**
 * 是否字符串
 */
export function isString(str) {
	if (typeof str === "string" || str instanceof String) {
		return true;
	}
	return false;
}

/**
 * 是否数组
 */
export function isArray(arg) {
	if (typeof Array.isArray === "undefined") {
		return Object.prototype.toString.call(arg) === "[object Array]";
	}
	return Array.isArray(arg);
}

/**
 * 获取随机整数
 */
export function randomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * 打乱数组元素顺序
 */
export function shuffle(arr) {
	const _arr = arr.slice();
	for (let i = 0; i < _arr.length; i++) {
		const j = randomInt(0, i);
		const t = _arr[i];
		_arr[i] = _arr[j];
		_arr[j] = t;
	}
	return _arr;
}

/**
 * 防抖函数
 */
export function debounce(func, delay = 200) {
	let timer;

	return function (...args) {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}

/**
 * dom对象是否在视口内
 */
export function isVisible(el) {
	return el.offsetWidth + el.offsetHeight > 0;
}

/**
 * 获取链接参数
 */
export function getQueryString(url) {
	let ItemArr = [];
	let ItemObj = {};
	url.substring(url.lastIndexOf("?") + 1, url.length)
		.split("&")
		.map(item => {
			ItemArr.push(item.split("="));
		});
	ItemArr.map(item => {
		ItemObj[item[0]] = item[1];
	});
	return ItemObj;
}

/**
 * 生成唯一id
 */
export function uniqueId() {
	let date = new Date();
	let time = Date.now().toString(36);
	// 当前随机数转成 36 进制字符串
	let random = Math.random().toString(36);
	// 去除随机数的 0. 字符串
	random = random.substring(2, random.length);
	// 返回唯一ID
	let id =
		date.getFullYear() +
		"-" +
		fillZero(date.getMonth() + 1) +
		"-" +
		fillZero(date.getDate()) +
		"/" +
		random +
		time;
	return id;
}

export function fillZero(str) {
	var realNum;
	if (str < 10) {
		realNum = "0" + str;
	} else {
		realNum = str;
	}
	return realNum;
}


//获取当前时间的YYMMDDHHMMSS字符   
export function getNowStr(date){
  let now=new Date();
  if(date){
	now=date;
  }
  return `${now.getFullYear()}`
  +`${fillZero(now.getMonth()+1)}`
  +`${fillZero(now.getDate())}`
  +`${fillZero(now.getHours())}`
  +`${fillZero(now.getMinutes())}`
  +`${fillZero(now.getSeconds())}`;
}