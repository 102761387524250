export default {
	getFeishuToken: "/api/feishu/oauth/code", // 对接账号中心根据code获取token
	getFeishuUser: "/api/account/account_info", // 根据工号获取用户信息
	configParameters: "/api/feishu/jsapi_config_parameters", // 飞书鉴权参数
	configUserCenter: "/api/feishu/config", // ihaier获取登录参数
	configUserInfo: "/api/feishu/user_info", // ihaier获取用户信息
	configOpenId: "/api/feishu/feishu_open_ids", // ihaier获取选人组件禁用用户
	getUrl: "/api/config/front", // 获取ws，appid等配置
	login: "/api/user/login", // 用户登录
	logout: "", // 退出登录
	sendSms: "/api/sms/send", // 发送手机验证码
	companyInfo: "/api/tenant_company/", // 企业信息
	updatePhone: "api/user/update/phone", // 修改手机号
	updateName: "/api/user/update/person_name", // 修改姓名
	updateEmail: "/api/user/update/user_email", // 修改邮箱
	updateAvatar: "/api/user/image_url/", //修改用户头像
	agreement: "/api/cms/user_policy", // 隐私协议
	// updatePassword: '/api/user/update/user_password',//修改密码
	updatePassword: "/api/user/reset/user_password_by_phone", // 根据用户手机号修改密码
	getVideoInfo: "api/video/ffprobe", // 获取视频信息
	createEvent: "/api/status/create_event", // 数据中台埋点
	getRoles: "/api/data_center/dashboard_role_info",
	getUserInfo: "/api/user/my_info", // 获取用户信息

	// 用户
	Users: "/api/user/", //用户
	userList: "/api/user/", //用户列表
	addUser: "/api/user/", //添加用户
	editUser: "/api/user/", //编辑用户
	delUser: "/api/user/", //删除用户
	batchDelUser: "/api/user/update/batch_delete_user", //删除用户
	updateRole: "/api/user/update/role", // 变更角色
	updateOrgan: "/api/user/update/org", // 变更组织

	// 权限
	rightsList: "/api/rights/", //权限列表
	OperateRight: "/api/user/operate_right", // 当前登录人的权限

	// 角色
	Roles: "/api/roles/", // 角色增删改查
	RoleUsers: "/api/user_roles/", // 查询角色下的用户

	// 标签
	Tag: "/api/tag/", // 标签增删改查
	TagStatis: "/api/tag/stat", //标签被引用情况
	musicContent: "/api/programs/music", // 点歌台相关信息
	allTags: "/api/tag/all_tag_list", // 获取用户下所有标签
	// 组织
	TenantOrg: "/api/tenant_org/", // 组织增删改查
	OrgTree: "/api/tenant_org/tree", // 组织树
	isDelOrgan: "/api/tenant_org/check_delete/", //根据组织id，检测是否允许删除有场景、素材、发布引用时，不允许删除
	setOrgCenter: "/api/tenant_org/set_default/", // 设置中心点
	getOrgIds: "/api/tenant_org/org_ids", // 获取一维数组
	// 日志
	Log: "/api/user_log/", // 操作日志
	// 场景'
	Scene: "/api/scenes/", // 场景增删改查
	batchDelScenes: "/api/scenes/update/batch_delete", // 批量删除场景
	changeOrg: "/api/scenes/org_id", // 批量更改组织
	changeQueue: "/api/scenes/enable_queue", // put用于修改是否排队播放
	regions: "/api/regions/", // 地区增删改查
	sceneStatis: "/api/scenes/stat", //组织下的场景统计及场景下的设备数量
	sceneAll: "/api/players/scene_all", // 所有场景包含播放器
	allScenes: "/api/scenes/all_scene_list", // 获取用户下所有场景
	// 播放器
	Players: "/api/players/", // 播放器相关
	changePlayerScene: "/api/players/scene", // 修改场景
	changePlayerEnable: "/api/players/enable", // 启用禁用
	playerlogout: "/api/players/logout", // 注销
	playersAll: "/api/players/all", // 获取场景下所有播放器

	// 设备
	Device: "/api/devices/",
	changeDeviceTags: "/api/devices/tags", // 批量修改设备标签
	changeDeviceEnable: "/api/devices/enable", // 修改启用状态
	changeDeviceScene: "/api/devices/scene", // 修改场景

	// 作品
	programs: "/api/programs/", // 作品相关
	programDetail: "/api/programs/", // 获取作品详情
	mulPrograms: "/api/programs/new_program", // 批量上传
	editProName: "/api/programs/name", // 修改作品名称
	changeworkOrgan: "/api/programs/org_id", // 批量更改组织
	changeWorkTag: "/api/programs/tag", // 批量修改作品标签
	mulVersions: "/api/program_versions/new_program_version", // 批量上传版本
	proVersion: "/api/program_versions/", // 节目版本
	editVersion: "/api/program_versions/name", // 修改版本名称
	sortVersion: "/api/program_versions/seq_no", // 修改版本顺序
	transcode: "/api/programs/update_is_transcode", // 开始转码
	versionTrans: "/api/programs/update_version_transcode", // 版本转码
	// 发布
	Publish: "/api/publish/", //发布项目
	endPublish: "/api/publish/done", // 结束发布
	publishOrg: "/api/publish/org_id", // 变更组织
	modifyName: "/api/publish/modify_name/", // 修改发布名称
	livePublish: "/api/publish/init_live/", // 学海直播发布
	enable_bullet: "/api/publish/enable_bullet", // 弹幕开关
	lock_time: "/api/publish/lock_time", // 获取发布锁定时间

	// 数据驾驶舱

	sceneStat: "/api/report/org/device_scene_stat", //获取组织场景统计

	//版本更新
	version: "/api/version/", //获取版本更新列表(列表  {id}删除)
	player: "/api/version/player", //新增版本

	//公共资源库（原视频中心）
	video: "/api/video/", //获取列表
	///内部共享组
	isShare: "/api/programs/is_share", // 作品相关

	///数据统计
	download: "/api/data_center/download", //数据统计  文件导入
	queryDevicePlayRecord: "/api/data_center/query_device_play_record", //播放记录明细查询
	downloadSceneRecordStat: "/api/data_center/download_scene_record_stat", //下载excel 导出场景播放记录数据
	downloadDeviceRecordDetail: "api/data_center/download_device_record_detail", //下载excel 导出设备播放明细数据

	///工作台
	dashboardRoleInfo: "/api/data_center/dashboard_role_info", //角色名称
	dashboardDeviceTotalStat: "/api/data_center/dashboard_device_total_stat", //设备总数(水球)
	dashboardDeviceOnlineDayStat:
		"/api/data_center/dashboard_device_online_day_stat", //近七日设备在线数
	dashboardPublishDayStat: "/api/data_center/dashboard_publish_day_stat", //近七日发布数
	dashboardUploadDayStat: "/api/data_center/dashboard_upload_day_stat", //近七日作品上传数
	dashboardMonthTotalstat: "/api/data_center/dashboard_month_total_stat", //月度数据

	mergeMedia:'api/programs/merge_media',//合成视频
};
