/** 全局配置表 */
console.log("当前环境", process.env.NODE_ENV);
// api请求地址
export const baseUrl = process.env.VUE_APP_BASEURL;
// oss地址
const oss = process.env.VUE_APP_OSS_BASEURL;
export const ossImageUrl = oss + "/picture/info?status=1";
export const ossVideoUrl = oss + "/videos/info?status=1";

// 域名
export const hostUrl =
	process.env.NODE_ENV == "production"
		? "https://hiculture.lanbenzi.cn/"
		: process.env.NODE_ENV == "test"
		? "https://hiculture-test.lanbenzi.cn/"
		: "http://localhost:8080/";
// 图片库和视频库地址
export const pictureUrl =
	process.env.NODE_ENV == "production"
		? "https://hiso.haier.net"
		: "https://hiso-dev.lanbenzi.cn/";
export const videoUrl =
	process.env.NODE_ENV == "production"
		? "https://history-prod.hilook.com.cn/"
		: "https://history-member-test.lanbenzi.cn/";
// 高德key和自定义样式
export const gaodeKey = process.env.VUE_APP_GAODEKEY;
export const gaodePassword = process.env.VUE_APP_GAODEPASSWORD;
export const gaodeStyle = "amap://styles/e5377c6e29839f3ea50b6ba62652800a";
export const gaodeUrl = `https://webapi.amap.com/maps?v=1.4.8&key=${gaodeKey}&plugin=AMap.Autocomplete,AMap.PlaceSearch,Map3D`;
// ws和appid从后台获取，在此做备份
export let wsUrl = "ws://hiculture.lanbenzi.cn/api/ws/screen"; // 正式环境
if (window.location.origin == "https:") {
	wsUrl = "wss://hiculture.lanbenzi.cn/api/ws/screen";
}

// ihaier账号中心地址
export const iHaierUrl =
	process.env.NODE_ENV == "production"
		? "https://iama.haier.net"
		: "https://iam-test.haier.net";
// 帮助中心连接
export const helpUrl =
	process.env.NODE_ENV == "production"
		? "https://ihaier-helpcenter.haier.net/helpevaluate/index?map=%7B%22%E5%B8%AE%E5%8A%A9%E4%B8%AD%E5%BF%83%22:%5B%22in%22,%22bzzx%22%5D%7D&applyCode=S03264&sceneCode=230419366482"
		: "https://vipportal-test.haier.net/helpevaluate/index?map=%7B%22%E5%B8%AE%E5%8A%A9%E4%B8%AD%E5%BF%83%22:%5B%22in%22,%22bzzx%22%5D%7D&applyCode=S03264&sceneCode=230417404509";
export const playerUrl =
	process.env.NODE_ENV == "production"
		? "https://hiculture-webplayer.lanbenzi.cn"
		: process.env.NODE_ENV == "test"
		? "https://hiculture-webplayer-test.lanbenzi.cn"
		: "https://localhost:7107/";
		// :'https://hiculture-webplayer-test.lanbenzi.cn'

// export const accessKeyId = 'LTAIUNqG9OSkIqry'
// export const accessKeySecret ='BLU1D6mmXhMpCWzevMmFXqvtJSVfIP'
// 上传oss配置
export const accessKeyId = 'LTAI5t7Dhy5XmXvniu5uwreR'
export const accessKeySecret ='OenX9agMf4TJJ1Cu15bn5jm4r9rjJI'

// export const wsUrl = 'ws://hiculture-test.lanbenzi.cn/api/ws/screen';// 测试环境和开发环境
// 接入海尔人appid
// export const appid = 'PTWzd6VRlTbI_ZoA80wvc'; // 测试环境
// const appid = 'PTWzd6VRlTbI_ZoA80wvc'; // 正式环境
// 数据驾驶舱免登录账号
// 测试环境：CustomerId=HC22224400019laN5d0
// 正式环境：CustomerId=HC22251609989Ps11P9

// 视频抓帧
// export const videoImgUrl = "/capture";
// oss截帧
// http://a-image-demo.oss-cn-qingdao.aliyuncs.com/demo.mp4?x-oss-process=video/snapshot,t_10000
// http://video.lanbenzi.cn/videos/redirect?path=uploads/2022-09-30/9c893f102d39e0bcdc16c6deebc7cffe.mp4?x-oss-process=video/snapshot,t_10000
