const WorksManage = () => import('@/view/works/works-manage');
const PublishManage = () => import('@/view/works/publish-manage');
const AuditManage = () => import('@/view/works/audit-manage');
const WorkDetail = () => import('@/view/works/work-detail');
const WorkAdd = () => import('@/view/works/work-add');
const VideoCenter = () => import('@/view/works/video-center');
const DataStatistics = () => import("@/view/statistics/data-statistics");

export default [
    {
        path: '/works-manage',
        name: 'WorksManage',
        component: WorksManage
    },
    {
        path: '/publish-manage',
        name: 'PublishManage',
        component: PublishManage
    },
    {
        path: '/work-detail',
        name: 'WorkDetail',
        component: WorkDetail
    },
    {
        path: '/work-add',
        name: 'WorkAdd',
        component: WorkAdd
    },
    {
        path: '/audit-manage',
        name: 'AuditManage',
        component: AuditManage
    },
    {
        path: '/video-center',
        name: 'VideoCenter',
        component: VideoCenter
    },
    {
        path: '/data-statistics',
        name: 'DataStatistics',
        component: DataStatistics
    }
];
