import request from "../../request/request";
import apis from "../../request/api";
import { getPlatform, isPc } from "../../assets/js/dcmFunc";
import { eventInfo } from "../../assets/js/enum";

const state = {
	token: sessionStorage.getItem("token")
		? JSON.parse(sessionStorage.getItem("token"))
		: "", // 当前登录的token
	user: sessionStorage.getItem("user")
		? JSON.parse(sessionStorage.getItem("user"))
		: {},
	menuIndex: sessionStorage.getItem("menuIndex")
		? JSON.parse(sessionStorage.getItem("menuIndex"))
		: "",
	keepAliveList: [],
	dataDrive: null, // 数据驾驶舱窗口
	tokening: sessionStorage.getItem("tokening")
		? JSON.parse(sessionStorage.getItem("tokening"))
		: "N", // 是否获取token中（废弃）
};
const getters = {
	token: state => state.token,
	user: state => state.user,
	menuIndex: state => state.menuIndex,
	keepAliveList: state => state.keepAliveList,
	dataDrive: state => state.dataDrive,
	tokening: state => state.tokening,
};
const mutations = {
	setToken(state, data) {
		sessionStorage.setItem("token", JSON.stringify(data));
		state.token = JSON.parse(sessionStorage.getItem("token"));
	},
	setTokending(state, data) {
		sessionStorage.setItem("tokening", JSON.stringify(data));
		state.tokening = JSON.parse(sessionStorage.getItem("tokening"));
	},
	clearToken() {
		sessionStorage.removeItem("token");
	},
	clearUser() {
		sessionStorage.removeItem("user");
	},
	setDataDrive(state, data) {
		state.dataDrive = data;
	},
	clearAll(state) {
		// sessionStorage.clear()
		sessionStorage.clear();
		localStorage.clear();
		if (state.dataDrive) {
			state.dataDrive.close();
		}
	},
	setUser(state, data) {
		sessionStorage.setItem("user", JSON.stringify(data));
		state.user = JSON.parse(sessionStorage.getItem("user"));
	},
	setMenu(state, data) {
		sessionStorage.setItem("menuIndex", JSON.stringify(data));
		state.menuIndex = JSON.parse(sessionStorage.getItem("menuIndex"));
	},
	pushKeepAliveList(state, item) {
		let set = new Set(state.keepAliveList);
		set.add(item);
		state.keepAliveList = Array.from(set);
	},
	popKeepAliveList(state, item) {
		let set = new Set(state.keepAliveList);
		if (set.has(item)) {
			set.delete(item);
		}
		state.keepAliveList = Array.from(set);
	},
};
const actions = {
	sendEvent({ state }, type) {
		const isHaier = getPlatform() == "iHaier";
		request.eventPost(apis.createEvent, {
			employee_no: state.user.UserName,
			event_time: new Date().getTime(),
			source: isPc() ? "PC端" : "手机端",
			event_name: "hiculture_page_sw",
			module_id: 39,
			entry_via: isHaier ? 2 : 8,
			...eventInfo[type],
		});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
