import { action } from './action.js';
import { baseUrl, videoImgUrl } from './config';
export default class request {
    static get(address, params) {
        let url = baseUrl + address;
        return action(
            {
                url,
                method: 'get',
                params: { ...params, TenantCode: "t.haier.wh" },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                        ? `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
                        : ''
                },
              
            }
        )
    }


    static post(address, data) {
        let url = baseUrl + address;
        return action({
            url,
            method: 'post',
            data: { ...data, TenantCode: "t.haier.wh" },
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token')
                    ? `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
                    : ''
            }
        });
    }
    static put(address, data) {
        let url = baseUrl + address;
        return action({
            url,
            method: 'put',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token')
                    ? `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
                    : ''
            }
        });
    }
    static delete(address, data) {
        let url = baseUrl + address;
        return action({
            url,
            method: 'delete',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token')
                    ? `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
                    : ''
            }
        });
    }
    static upPic(address, data) {
        let url = address;
        let param = new FormData(); // 创建form对象
        param.append('title', data.name);
        param.append('file', data.file);
        return action({
            method: 'post',
            url,
            data: param,
            headers: {
                appid: "zhanban",
                appsecret: "f965f9a47816f6197d0ce5dca9045325",
                // 'Content-Type': 'multipart/form-data'
            }
        });
    }
    static capture(data) {
        let url = videoImgUrl;
        let param = new FormData(); // 创建form对象
        // param.append('url', data.url);
        // param.append('time', data.time);
        param.append('files', JSON.stringify(data));
        return action({
            url,
            method: 'post',
            data: param,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
    static eventPost(address, data) {
        let url = baseUrl + address;
        return action({
            url,
            method: 'post',
            data: { ...data},
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token')
                    ? `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`
                    : ''
            }
        });
    }
}
